import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"

const HyperCT = styled.h1`
  text-align: center;
  font-size: 3rem;
  font-family: "Latin Modern Roman";
  font-style: italic;
  color: tomato;
  }
`

const CategoryPage = () => (
  <Layout>
    <HyperCT>
      c a t Z
    </HyperCT>
  </Layout>
)

export default CategoryPage

// import React from "react"
// import { graphql } from "gatsby"
// import Layout from "../components/layout"
// import styled from "styled-components"
// // import SEO from "../components/seo"

// const HyperCT = styled.h1`
//   text-align: center;
//   font-size: 3rem;
//   font-family: "Latin Modern Roman";
//   font-style: italic;
//   color: chartreuse;
//   }
// `

// export default ({ data }) => {
//   const post = data.markdownRemark
//   return (
//     <Layout>
//       {/* <SEO title={post.frontmatter.title} /> */}
//       {/* <HyperCT>{post.frontmatter.title}</HyperCT> */}
//       <HyperCT>Posts in category "${category}"</HyperCT>
//       {/* <Turner /> */}
//     </Layout>
//   )
// }

// export const query = graphql`
//   query($category: String) {
//     allMarkdownRemark(
//       filter: { frontmatter: { category: { eq: $category } } }
//     ) {
//       edges {
//         node {
//           frontmatter {
//             title
//           }
//         }
//       }
//     }
//   }
// `